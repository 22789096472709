import StatisticsView from './StatisticsView'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { RootState } from '../../store'
import { fetchAllLocations } from '../Locations/locationsSlice'
import { fetchDailyStatistics } from './StatisticsSlice'
import './Statistics.css'

export default () => {
    const dispatch = useAppDispatch()
    const locations = useAppSelector((state: RootState) => state.locations)
    const statistics = useAppSelector((state: RootState) => state.statistics)
    useEffect(() => {
        dispatch(fetchAllLocations())
    }, [])

    const handleStatisticsFetch = (
        locationId: string,
        startDate: string,
        endDate: string,
        searchQuery: string,
        hidden: boolean
    ) => {
        dispatch(
            fetchDailyStatistics({
                locationId,
                startDate,
                endDate,
                searchQuery,
                hidden,
            })
        )
    }
    return (
        <StatisticsView
            statistics={statistics.data}
            locations={locations.data}
            loading={statistics.isLoading}
            handleStatisticsFetch={handleStatisticsFetch}
        />
    )
}
