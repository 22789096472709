import React, { useEffect, useState } from 'react'
import { Box, Flex, useDisclosure } from '@chakra-ui/react'
import Sidebar from '../components/Sidebar/Sidebar'
import { Navigate, Outlet } from 'react-router-dom'
import { isAuth } from '../services/auth'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { fetchAllOnlineOrders } from '../containers/OnlineOrders/onlineOrderSlice'
import { useAppDispatch } from '../hooks'

export default () => {
    const { isOpen, onToggle } = useDisclosure()
    const dispatch = useAppDispatch()
    if (!isAuth()) {
        return <Navigate to={'/login'} replace />
    }
    const token = isAuth()
    const WS_URL = `${
        process.env.REACT_APP_SOCKET_SERVER || 'ws://127.0.0.1:3001'
    }/?token=${token}`
    const {
        sendJsonMessage,
        lastJsonMessage,
        readyState,
        getWebSocket,
        lastMessage,
    } = useWebSocket(WS_URL, {
        share: false,
        shouldReconnect: () => true,
    })
    const [newOrder, setNewOrder] = useState(false)
    const [audio] = useState(new Audio('/bell.wav'))
    const [playing, setPlaying] = useState(false)

    const toggle = () => setPlaying(!playing)

    useEffect(() => {
        playing ? audio.play() : audio.pause()
    }, [playing])

    useEffect(() => {
        audio.addEventListener('ended', () => setPlaying(false))
        return () => {
            getWebSocket()?.close()
            audio.removeEventListener('ended', () => setPlaying(false))
        }
    }, [])

    useEffect(() => {
        console.log('Connection state changed', readyState, ReadyState)
        if (readyState === ReadyState.OPEN) {
            sendJsonMessage({
                event: 'subscribe',
                data: {
                    channel: 'general-chatroom',
                },
            })
        }
    }, [readyState])
    const messageProcessing = async (jsonMessage: any) => {
        console.log(`Got a new message: ${JSON.stringify(jsonMessage)}`)
        const jsonParsed = JSON.parse(jsonMessage)
        switch (jsonParsed.status) {
            case 'orderCreated':
                await audio.play()
                setNewOrder(true)
                if (window.location.pathname === '/dashboard/orders/online') {
                    setTimeout(() => {
                        window.location.reload()
                    }, 500)
                }
                break
            default:
                setNewOrder(false)
                break
        }
    }

    // Run when a new WebSocket message is received (lastJsonMessage)
    useEffect(() => {
        messageProcessing(lastJsonMessage || '')
        console.log('JSON MESSAGE', lastJsonMessage, lastMessage)
    }, [lastJsonMessage])
    return (
        <Flex style={{ background: '#f4f7fe' }}>
            <Sidebar
                isOpen={isOpen}
                onToggle={onToggle}
                newOrder={newOrder}
                setNewOrder={setNewOrder}
            />
            <Box w="100%" p={10} overflowX={'scroll'}>
                <Outlet />
            </Box>
        </Flex>
    )
}
