import { useAppDispatch, useAppSelector } from '../../hooks'
import { RootState } from '../../store'
import { useEffect } from 'react'
import {
    fetchAllProductsForLocation,
    updateProductAvailabilityAction,
} from './productsSellerSlice'
import ProductsView from './ProductsSellerView'
import { setProduct } from '../ProductForm/productFormSlice'
import { useToast } from '@chakra-ui/react'

export default () => {
    const dispatch = useAppDispatch()
    const products = useAppSelector((state: RootState) => state.productSeller)
    const productForm = useAppSelector((state: RootState) => state.productForm)
    const toast = useToast()
    useEffect(() => {
        dispatch(fetchAllProductsForLocation())
    }, [])
    const setProductForm = (data: any) => {
        dispatch(setProduct(data))
    }
    const changeItemAvailability = async (
        productId: string,
        disabled: boolean
    ) => {
        try {
            await dispatch(
                updateProductAvailabilityAction({ productId, disabled })
            )
            dispatch(fetchAllProductsForLocation())
            toast({
                title: 'Product',
                description: 'Product availability was changed successfully.',
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        } catch (e) {
            toast({
                title: 'Product',
                description:
                    'Whoops! Something went wrong when editing the product',
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }
    return (
        <ProductsView
            data={products.data}
            isProductFormVisible={productForm.isVisible}
            setProductForm={setProductForm}
            changeAvailability={changeItemAvailability}
        />
    )
}
