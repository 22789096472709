import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getLocations } from '../../repository/locations'
import {
    deleteProduct,
    getProducts,
    getProductsForLocation,
    updateProductAvailability,
} from '../../repository/products'
import { getLocalAccount } from '../../services/account'

interface IProductsSlice {
    isLoading: boolean
    data: any[]
    error: boolean
}

export const fetchAllProductsForLocation = createAsyncThunk(
    'productsSeller/fetchAllProducts',
    async (arg, thunkAPI) => {
        const userAccount = getLocalAccount()
        const response = await getProductsForLocation(userAccount.location)
        return response.data.menu
    }
)

export const updateProductAvailabilityAction = createAsyncThunk(
    'productsSeller/updateProductAvailability',
    async (
        { productId, disabled }: { productId: string; disabled: boolean },
        thunkAPI
    ) => {
        const userAccount = getLocalAccount()
        const response = await updateProductAvailability(
            userAccount.location,
            productId,
            disabled
        )
        return response.data
    }
)

const initialState: IProductsSlice = {
    isLoading: false,
    data: [],
    error: false,
}
const productsSellerSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        setProductsAction: (state, action: PayloadAction<any[]>) => {
            state.data = action.payload
        },
    },
    extraReducers: (builder) =>
        builder
            .addCase(fetchAllProductsForLocation.pending, (state, action) => {
                state.isLoading = true
                state.error = false
            })
            .addCase(fetchAllProductsForLocation.fulfilled, (state, action) => {
                state.isLoading = false
                state.error = false
                state.data = action.payload
            })
            .addCase(
                updateProductAvailabilityAction.pending,
                (state, action) => {
                    state.isLoading = true
                    state.error = false
                }
            )
            .addCase(
                updateProductAvailabilityAction.fulfilled,
                (state, action) => {
                    state.isLoading = false
                    state.error = false
                }
            ),
})

export const { setProductsAction } = productsSellerSlice.actions

export default productsSellerSlice.reducer
