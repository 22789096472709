import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    createOrder,
    deleteOrder,
    getOrders,
    setOrderDiscount,
    setOrderStatus,
} from '../../repository/orders'
import { ORDER_STATUS } from '../OrderForm/orderFormSlice'
import dayjs from 'dayjs'

interface IAdminOrderSlice {
    isLoading: boolean
    data: any[]
    error: boolean
    reachedEnd: boolean
    lastKey: any
}

export const fetchAllAdminOrders = createAsyncThunk(
    'adminOrders/fetchAllOrders',
    async (
        {
            locationId,
            orderId,
            createdAt,
        }: {
            locationId: string
            orderId?: string
            createdAt?: number | string
        },
        thunkAPI
    ) => {
        const response = await getOrders(locationId, orderId, createdAt, true)
        return response.data
    }
)

export const deleteOrderAsOwner = createAsyncThunk(
    'adminOrders/deleteOrderAsOwner',
    async ({ orderId }: { orderId: string }): Promise<any> => {
        const response = await deleteOrder(orderId)
        return { orderId }
    }
)

const initialState: IAdminOrderSlice = {
    isLoading: false,
    reachedEnd: false,
    data: [],
    error: false,
    lastKey: {},
}
const adminOrdersSlice = createSlice({
    name: 'adminOrders',
    initialState,
    reducers: {
        resetState: (state) => {
            state.data = []
            state.lastKey = {}
            state.isLoading = false
            state.error = false
            state.reachedEnd = false
        },
        emptyAdminOrderList: (state) => {
            state.data = []
        },
    },
    extraReducers: (builder) =>
        builder
            .addCase(fetchAllAdminOrders.pending, (state, action) => {
                state.isLoading = true
                state.error = false
            })
            .addCase(fetchAllAdminOrders.fulfilled, (state, action) => {
                state.isLoading = false
                state.error = false
                const orders = action.payload.orders
                state.data = [
                    ...state.data,
                    ...orders.map((order: any) => {
                        return {
                            ...order,
                            shortOrderId: order.orderId.split('-').pop(),
                        }
                    }),
                ]
                state.lastKey = action.payload?.lastKey || {}
                state.reachedEnd = !action.payload?.orders?.length
            })
            .addCase(deleteOrderAsOwner.pending, (state, action: any) => {
                state.isLoading = true
                state.error = false
            })
            .addCase(deleteOrderAsOwner.fulfilled, (state, action) => {
                state.isLoading = false
                state.error = false
                state.data = state.data.filter(
                    (order) => order.orderId !== action?.payload?.orderId
                )
            }),
})

export const { resetState, emptyAdminOrderList } = adminOrdersSlice.actions

export default adminOrdersSlice.reducer
