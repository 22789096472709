import React, { useState } from 'react'
import {
    Box,
    Button,
    Divider,
    Flex,
    IconButton,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
} from '@chakra-ui/react'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import CustomTable from '../../components/CustomTable/CustomTable'
import LocationForm from '../LocationForm/LocationForm'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { setLocation } from '../LocationForm/locationFormSlice'
import { ImEye } from 'react-icons/im'
import { setProduct } from '../ProductForm/productFormSlice'
import ProductForm from '../ProductForm/ProductForm'
import { DeleteIcon } from '@chakra-ui/icons'
import { FaAdjust } from 'react-icons/all'

export default ({
    data,
    isProductFormVisible,
    setProductForm,
    changeAvailability,
}: {
    data: any[]
    isProductFormVisible: boolean
    setProductForm: Function
    changeAvailability: Function
}) => {
    const dispatch = useAppDispatch()
    const [itemToChangeAvailability, setItemToChangeAvailability] = useState(
        null as null | any
    )

    const { isOpen, onOpen, onClose } = useDisclosure()
    const stageItemForDeletion = (data: any) => {
        onOpen()
        setItemToChangeAvailability(data)
    }
    const confirmChangeItemAvailability = () => {
        changeAvailability(
            itemToChangeAvailability.productId,
            !itemToChangeAvailability.disabled
        )
        setItemToChangeAvailability(null)
        onClose()
    }

    const cols = [
        { label: 'Name', field: 'name' },
        { label: 'Type', field: 'productType' },
        { label: 'Price', field: 'price' },
        { label: 'Disabled', field: 'disabled' },
        {
            label: 'Actions',
            field: '',
            type: 'icons',
            icons: [
                {
                    show: true,
                    icon: <FaAdjust />,
                    callbackMethod: stageItemForDeletion,
                },
            ],
        },
    ]
    return (
        <>
            <Breadcrumbs />
            <Divider my={30} />
            <Flex my={10} justifyContent={'flex-end'}></Flex>
            <CustomTable columns={cols} data={data} />
            {isProductFormVisible && <ProductForm key={Date.now()} />}
            {isOpen && (
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Changed product availability</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            Are you sure that you want to change availability of
                            this product?
                        </ModalBody>

                        <ModalFooter>
                            <Button variant="ghost" mr={3} onClick={onClose}>
                                No
                            </Button>
                            <Button
                                colorScheme="messenger"
                                onClick={confirmChangeItemAvailability}
                            >
                                Yes
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            )}
        </>
    )
}
